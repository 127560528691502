<template>
  <div class="absolute flex gap-24 right-0 top-[-85px]">
    <s-button
      v-if="isReportButtonShown()"
      variant="secondary"
      size="md"
      class="!min-w-[11.6rem]"
      :isDisabled="isReportButtonDisabled()"
      @click="onClickRevision"
    >
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change_btn') }}
    </s-button>
    <s-button
      variant="primary"
      size="md"
      class="!min-w-[11.6rem]"
      :isDisabled="isRegisterButtonDisabled()"
      @click="onRegisterReview"
    >
      {{
        $t(
          isGuideShown
            ? 'studio.prj_prod_mngmt.rating_build_review.self_review.review_req_btn'
            : 'studio.prj_prod_mngmt.rating_build_review.reg_btn'
        )
      }}
    </s-button>
  </div>
  <div class="mt-8 flex">
    <span
      class="cursor-pointer ml-auto flex items-center gap-4 text-md leading-sm font-medium text-on-surface-elevation-2"
      @click="navigateToRatingHistory"
    >
      <s-icon icon="ic-v2-community-board-all-line" size="3xl" class="flex" />
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.see_history_btn') }}
    </span>
  </div>
  <rating-guide v-if="isGuideShown" />
  <component
    :is="getRatingComponent"
    v-else
    :rating="rating"
    :launchInfoFieldChanged="launchInfoFieldChanged"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, defineAsyncComponent, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchProductStatusApi } from '@/apis/products.api';
import { fetchRatingProductLaunchInfoApi, fetchRatingsProductsApi, getDetailProductRatingApi } from '@/apis/rating.api';
import RatingGuide from '@/components/rating/rating-guide.vue';
import RatingSurveyDialog from '@/components/rating/rating-survey-dialog.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { PLATFORM } from '@/constants/common.const';
import { VERIFY_STATUS } from '@/constants/products.const';
import { RATING_RESULT_TAB, type RatingResultTab } from '@/constants/rating.const';
import { PRODUCT_RATING_HISTORY_URL, PRODUCT_RATING_REGISTER_URL } from '@/constants/url.const';
import { BusinessReviewStatus } from '@/enums/business.enum';
import { Confirmation } from '@/enums/common.enum';
import {
  AdditionalRatingType,
  RatingPageMode,
  RatingStatus,
  RatingTypeStatus,
  SurveyDialogType,
  VerifyStatus
} from '@/enums/rating.enum';
import useProductStore from '@/stores/product.store';
import type { RunOptionGroup } from '@/types/launch-settings/launch-settings.type';
import type {
  RatingProductResponse,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import { redirectTo } from '@/utils/common.util';
import {
  getChangedRatingInformation,
  getCorrectRating,
  isVerifyingStatus,
  isVerifyPassStatus,
  isVerifyRejectStatus
} from '@/utils/rating.util';

definePageMeta({
  showProductKey: true,
  middleware: 'handle-product-data-middleware'
});

const { checkProductPermission } = useApp();

const { t } = useI18n();
const route = useRoute();
const productStore = useProductStore();
const { isPreRelease, product, isReleased, productRunOptions } = storeToRefs(productStore);

const productNo = route.params.productId as string;

const isGuideShown = ref<boolean>(true); // The guide is shown only when the product has not been rated yet

const currentActivePageKey = ref<RatingResultTab>();
const rating = ref<RatingProductResponse>();
const launchInfoFieldChanged = ref<string[]>([]);
const isProductReleaseVerifying = ref<boolean>(false);

const onRegisterReview = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  const ratingProductRes = await getDetailProductRatingApi(productNo);
  if (!ratingProductRes || !ratingProductRes.businessInfo || !ratingProductRes.businessInfo.approveInfo) {
    showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.popup_no_info')
    });

    return;
  }

  if (ratingProductRes.businessInfo.temporaryReviewInfo.companyStatus === BusinessReviewStatus.Request) {
    showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_reg_x')
    });

    return;
  }

  if (Array.isArray(rating.value?.rating)) {
    if (rating.value?.rating[0].status === RatingStatus.LINKED) {
      if (rating.value?.rating[0].directYn === Confirmation.YES) {
        const confirm = await showConfirm({
          content: [
            `<strong>${t(
              'studio.prj_prod_mngmt.rating_build_review.direct_input.reg_completed.rating_existed_guide'
            )}</strong>`,
            t(
              'studio.prj_prod_mngmt.rating_build_review.direct_input.reg_completed.rating_existed_new_reg_guide'
            ),
            '<br/>',
            t(
              'studio.prj_prod_mngmt.rating_build_review.direct_input.reg_completed.rating_existed_edit_guide'
            )
          ],
          confirmLabel: t(
            'studio.prj_prod_mngmt.rating_build_review.direct_input.reg_completed.rating_existed_new_reg_btn'
          ),
          cancelLabel: t('studio.common.popup_case_cancel_btn'),
          cancelVariant: 'outline',
          panelClasses: '!w-[56rem]',
          cancelClasses: '!w-[12.8rem]',
          confirmClasses: '!w-[12.8rem]'
        });

        if (!confirm) {
          return;
        }

        await productStore.getProductPlatform();
        const isProductWebType = productRunOptions.value?.some((item: RunOptionGroup) => item.type === PLATFORM.WEB);
        if (isProductWebType) {
          await showAlert({
            content: t(
              'studio.rating_build_review.case_launch_setting_web.cannot_request_self_review_msg'
            )
          });

          return;
        }
      } else {
        const confirm = await showConfirm({
          content: t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.review_done_re_reg_guide'
          ),
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          cancelLabel: t('studio.common.popup_case_cancel_btn'),
          cancelVariant: 'outline',
          panelClasses: '!w-[56rem]',
          cancelClasses: '!w-[12.8rem]',
          confirmClasses: '!w-[12.8rem]'
        });

        if (!confirm) {
          return;
        }
      }
    }
  } else if (
    rating.value &&
    isVerifyPassStatus(rating.value?.rating?.status, rating.value?.rating?.verifyInfo?.verifyStatus)
  ) {
    const confirm = await showConfirm({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.review_done_re_reg_guide'),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline',
      panelClasses: '!w-[56rem]',
      cancelClasses: '!w-[12.8rem]',
      confirmClasses: '!w-[12.8rem]'
    });

    if (!confirm) {
      return;
    }
  }

  redirectTo(PRODUCT_RATING_REGISTER_URL);
};

const navigateToRatingHistory = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  redirectTo(PRODUCT_RATING_HISTORY_URL);
};

const getRatingComponent = computed(() => {
  if (!currentActivePageKey.value) {
    return;
  }
  return ratingComponents[currentActivePageKey.value as keyof typeof ratingComponents];
});

const ratingComponents = {
  [RATING_RESULT_TAB.ADJUSTMENT]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-adjustment.vue')
  ),
  [RATING_RESULT_TAB.CANCEL]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-cancel.vue')
  ),
  [RATING_RESULT_TAB.DIRECT_INPUT_COMPLETE]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-direct-input-complete.vue')
  ),
  [RATING_RESULT_TAB.OBJECTION]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-objection.vue')
  ),
  [RATING_RESULT_TAB.OBJECTION_IN_PROGRESS]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-objection-in-progress.vue')
  ),
  [RATING_RESULT_TAB.OBJECTION_REJECTION]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-objection-rejection.vue')
  ),
  [RATING_RESULT_TAB.RECLASSIFICATION]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-reclassification.vue')
  ),
  [RATING_RESULT_TAB.REJECTION]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-rejection.vue')
  ),
  [RATING_RESULT_TAB.PRE_RELEASE]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-pre-release.vue')
  ),
  [RATING_RESULT_TAB.MODIFICATION_VERIFYING]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-modification-verifying.vue')
  ),
  [RATING_RESULT_TAB.MODIFICATION_COMPLETE]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-modification-complete.vue')
  ),
  [RATING_RESULT_TAB.MODIFICATION_REJECTION]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-modification-rejection.vue')
  ),
  [RATING_RESULT_TAB.VERIFYING]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-verifying.vue')
  ),
  [RATING_RESULT_TAB.VERIFY_REJECTED]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-verify-reject.vue')
  ),
  [RATING_RESULT_TAB.REQUEST_ACCEPTED]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-self-review-completed.vue')
  ),
  [RATING_RESULT_TAB.OBJECTION_COMPLETE]: defineAsyncComponent(
    () => import('@/components/rating/rating-result-objection-complete.vue')
  )
};

const onClickRevision = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  if (rating.value?.requestRating?.createdAt) {
    const confirm = await showConfirm({
      content: [
        `<span class="font-bold">${t(
          'studio.build_rating_review.awaiting_rating_info_exist_msg1'
        )}</span>`,
        '<br />',
        t('studio.build_rating_review.awaiting_rating_info_exist_msg2')
      ],
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });

    if (!confirm) {
      return;
    }
  }

  const result = await showDialog({
    component: shallowRef(RatingSurveyDialog),
    props: {
      type: SurveyDialogType.REVISION,
      mode: RatingPageMode.EDIT
    }
  });

  if (result) {
    window.location.reload();
  }
};

const isAdjustedReclassification = computed(() => {
  if (Array.isArray(rating.value?.rating)) {
    return (
      rating.value?.rating[0].status === RatingStatus.ADJUSTED &&
      rating.value?.rating[0].type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE
    );
  }

  return (
    rating.value?.rating?.status === RatingStatus.ADJUSTED &&
    rating.value?.rating?.type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE
  );
});

const isRegisterButtonDisabled = () => {
  if (isProductReleaseVerifying.value) {
    return true;
  }

  const status = [RatingStatus.NOTICED, RatingStatus.ACCEPTED, RatingStatus.MODIFICATION];

  if (Array.isArray(rating.value?.rating)) {
    if (
      rating.value?.rating[0]?.status === RatingStatus.ACCEPTED &&
      (rating.value?.rating[0].type === RatingTypeStatus.RECLASSIFICATION ||
        rating.value?.rating[0].type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE)
    ) {
      return true;
    }

    if (isAdjustedReclassification.value) {
      if (rating.value?.rating[0].ageRating) {
        return false;
      }

      return true;
    }

    // 28-2-11
    if (checkObjectionComplete(rating.value.releasedRatings[0].status)) {
      return false;
    }

    if (
      rating.value.rating[0]?.status === RatingStatus.EXPLAINING &&
      (rating.value.rating[0].type === RatingTypeStatus.RATING_REJECTED ||
        rating.value.rating[0].type === RatingTypeStatus.RATING_CANCELED)
    ) {
      return true;
    }

    return status.includes(rating.value?.rating[0].status);
  }

  if (!rating.value?.rating?.status) {
    return false;
  }

  if (
    rating.value.rating?.status === RatingStatus.EXPLAINING &&
    (rating.value.rating.type === RatingTypeStatus.RATING_REJECTED ||
      rating.value.rating.type === RatingTypeStatus.RATING_CANCELED)
  ) {
    return true;
  }

  if (isAdjustedReclassification.value) {
    if (rating.value?.rating.ageRating) {
      return false;
    }

    return true;
  }

  return (
    status.includes(rating.value.rating.status) ||
    isVerifyingStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus)
  );
};

const isReportButtonDisabled = () => {
  const status = [RatingStatus.NOTICED, RatingStatus.ACCEPTED, RatingStatus.ADJUSTED];

  if (Array.isArray(rating.value?.rating)) {
    if (
      rating.value?.rating[0]?.status === RatingStatus.ACCEPTED &&
      (rating.value?.rating[0].type === RatingTypeStatus.RECLASSIFICATION ||
        rating.value?.rating[0].type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE)
    ) {
      return true;
    }

    if (isAdjustedReclassification.value) {
      if (rating.value?.rating[0].ageRating) {
        return true;
      }

      return false;
    }

    // 28-2-11
    if (checkObjectionComplete(rating.value.releasedRatings[0].status)) {
      return false;
    }

    if (
      rating.value.rating[0]?.status === RatingStatus.EXPLAINING &&
      (rating.value.rating[0].type === RatingTypeStatus.RATING_REJECTED ||
        rating.value.rating[0].type === RatingTypeStatus.RATING_CANCELED)
    ) {
      return true;
    }

    return status.includes(rating.value?.rating[0].status);
  }

  if (!rating.value?.rating?.status) {
    return false;
  }

  if (
    rating.value.rating?.status === RatingStatus.EXPLAINING &&
    (rating.value.rating.type === RatingTypeStatus.RATING_REJECTED ||
      rating.value.rating.type === RatingTypeStatus.RATING_CANCELED)
  ) {
    return true;
  }

  if (isAdjustedReclassification.value) {
    if (rating.value?.rating.ageRating) {
      return true;
    }

    return false;
  }

  if (
    isVerifyPassStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus) &&
    (rating.value.rating.type === RatingTypeStatus.GAME_REVISION ||
      rating.value.rating.type === RatingTypeStatus.GAME_REVISION_RECLASSIFICATION_COMMITTEE)
  ) {
    return false;
  }

  return (
    status.includes(rating.value.rating.status) ||
    isVerifyingStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus) ||
    isVerifyPassStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus)
  );
};

const isReportButtonShown = () => {
  const status = [
    RatingStatus.NOTICED,
    RatingStatus.ACCEPTED,
    RatingStatus.ADJUSTED,
    RatingStatus.REJECTED,
    RatingStatus.MODIFICATION,
    RatingStatus.LINKED,
    RatingStatus.CANCELED
  ];

  if (Array.isArray(rating.value?.rating)) {
    if (rating.value?.rating[0].directYn === Confirmation.YES) {
      return false;
    }

    if (
      rating.value?.rating[0]?.status === RatingStatus.ADJUSTED &&
      (rating.value?.rating[0].type === RatingTypeStatus.RATING_REJECTED ||
        rating.value?.rating[0].type === RatingTypeStatus.RATING_CANCELED)
    ) {
      return false;
    }

    if (
      rating.value.rating[0]?.status === RatingStatus.EXPLAINING &&
      (rating.value.rating[0].type === RatingTypeStatus.RATING_REJECTED ||
        rating.value.rating[0].type === RatingTypeStatus.RATING_CANCELED)
    ) {
      return true;
    }

    return status.includes(rating.value?.rating[0].status);
  }

  if (!rating.value?.rating?.status) {
    return false;
  }

  if (
    rating.value.rating?.status === RatingStatus.EXPLAINING &&
    (rating.value.rating.type === RatingTypeStatus.RATING_REJECTED ||
      rating.value.rating.type === RatingTypeStatus.RATING_CANCELED)
  ) {
    return true;
  }

  if (
    rating.value.rating.type === RatingTypeStatus.GAME_REVISION &&
    (isVerifyingStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus) ||
      isVerifyRejectStatus(
        rating.value.rating.status,
        rating.value.rating?.verifyInfo?.verifyStatus
      ))
  ) {
    return true;
  }

  // 28-12-9
  if (
    isVerifyingStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus) &&
    rating.value.requestRating?.type === AdditionalRatingType.OBJECTION &&
    rating.value.requestRating?.status === RatingStatus.SAVE
  ) {
    return true;
  }

  return (
    status.includes(rating.value.rating?.status) ||
    isVerifyPassStatus(rating.value.rating.status, rating.value.rating?.verifyInfo?.verifyStatus)
  );
};

const getStatus = (
  status: RatingStatus,
  verifyStatus?: VerifyStatus,
  type?: string
): RatingResultTab | undefined => {
  switch (status) {
    case RatingStatus.REJECTED:
      // 28-3-8
      if (
        rating.value?.requestRating &&
        rating.value.requestRating.type === AdditionalRatingType.OBJECTION &&
        rating.value.requestRating?.verifyInfo?.verifyStatus === VerifyStatus.REJECT
      ) {
        return RATING_RESULT_TAB.OBJECTION_REJECTION;
      }

      return RATING_RESULT_TAB.VERIFY_REJECTED;
    case RatingStatus.MODIFICATION: {
      if (type === RatingTypeStatus.RECLASSIFICATION) {
        return RATING_RESULT_TAB.ADJUSTMENT;
      }
      if (type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE) {
        return RATING_RESULT_TAB.RECLASSIFICATION;
      }

      return RATING_RESULT_TAB.MODIFICATION_VERIFYING;
    }
    // 28-12-21 + 28-12-19
    case RatingStatus.NOTICED:
      if (type === RatingTypeStatus.RATING_CANCELED) {
        return RATING_RESULT_TAB.CANCEL;
      }
      if (type === RatingTypeStatus.RATING_REJECTED) {
        return RATING_RESULT_TAB.REJECTION;
      }
      return undefined;
    case RatingStatus.VERIFY_REJECTED:
      return RATING_RESULT_TAB.REJECTION;
    case RatingStatus.SAVE:
      if (verifyStatus === VerifyStatus.PASS) {
        if (
          type === RatingTypeStatus.GAME_REVISION ||
          type === RatingTypeStatus.GAME_REVISION_RECLASSIFICATION_COMMITTEE
        ) {
          return RATING_RESULT_TAB.MODIFICATION_COMPLETE;
        }
        if (isReleased.value) {
          return RATING_RESULT_TAB.REQUEST_ACCEPTED;
        }
        return RATING_RESULT_TAB.OBJECTION;
      }
      if (verifyStatus === VerifyStatus.REJECT) {
        return RATING_RESULT_TAB.VERIFY_REJECTED;
      }

      if (type === RatingTypeStatus.GAME_REVISION) {
        return RATING_RESULT_TAB.MODIFICATION_VERIFYING;
      }

      // 28-3-6
      if (
        rating.value?.requestRating &&
        rating.value.requestRating.type === AdditionalRatingType.OBJECTION
      ) {
        return RATING_RESULT_TAB.OBJECTION_IN_PROGRESS;
      }

      // 28-12-6
      if (
        rating.value?.requestRating &&
        (rating.value.requestRating.type === RatingTypeStatus.GAME_REVISION ||
          rating.value.requestRating.type ===
            RatingTypeStatus.GAME_REVISION_RECLASSIFICATION_COMMITTEE ||
          rating.value.requestRating.type === RatingTypeStatus.GAME_REVISION_RECLASSIFICATION) &&
        (verifyStatus === VerifyStatus.REQUEST || verifyStatus === VerifyStatus.START)
      ) {
        return RATING_RESULT_TAB.MODIFICATION_VERIFYING;
      }

      return RATING_RESULT_TAB.VERIFYING;
    case RatingStatus.ACCEPTED:
      if (type === RatingTypeStatus.RECLASSIFICATION) {
        return RATING_RESULT_TAB.ADJUSTMENT;
      }
      if (type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE) {
        return RATING_RESULT_TAB.RECLASSIFICATION;
      }
      if (type === AdditionalRatingType.OBJECTION) {
        return RATING_RESULT_TAB.OBJECTION_COMPLETE;
      }

      return undefined;
    case RatingStatus.LINKED:
      // 28-3-7
      if (
        rating.value?.releasedRatings &&
        rating.value.releasedRatings[0].type === AdditionalRatingType.OBJECTION
      ) {
        return RATING_RESULT_TAB.MODIFICATION_COMPLETE;
      }

      if (type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE) {
        return RATING_RESULT_TAB.RECLASSIFICATION;
      }
      if (type === RatingTypeStatus.RATING_CANCELED) {
        return RATING_RESULT_TAB.NONE;
      }
      if (isPreRelease.value && launchInfoFieldChanged.value.length > 0) {
        return RATING_RESULT_TAB.PRE_RELEASE;
      }

      return RATING_RESULT_TAB.REQUEST_ACCEPTED;
    case RatingStatus.ADJUSTED:
      if (type === RatingTypeStatus.RECLASSIFICATION_COMMITTEE) {
        return RATING_RESULT_TAB.RECLASSIFICATION;
      }
      if (type === RatingTypeStatus.RATING_CANCELED) {
        return RATING_RESULT_TAB.NONE;
      }
      if (isPreRelease.value && launchInfoFieldChanged.value.length > 0) {
        return RATING_RESULT_TAB.PRE_RELEASE;
      }
      return RATING_RESULT_TAB.REQUEST_ACCEPTED;
    case RatingStatus.NOT_AVAILABLE:
      if (type === RatingTypeStatus.GAME_REVISION && verifyStatus === VerifyStatus.REJECT) {
        return RATING_RESULT_TAB.MODIFICATION_REJECTION;
      }
  }
};

const getStatusByType = (type: string): RatingResultTab | undefined => {
  switch (type) {
    case RatingTypeStatus.RECLASSIFICATION:
      return RATING_RESULT_TAB.ADJUSTMENT;
    case RatingTypeStatus.RECLASSIFICATION_COMMITTEE:
      return RATING_RESULT_TAB.RECLASSIFICATION;
    case RatingTypeStatus.RATING_CANCELED:
      return RATING_RESULT_TAB.CANCEL;
    case RatingTypeStatus.RATING_REJECTED:
      return RATING_RESULT_TAB.REJECTION;
  }
};

const checkObjectionComplete = (status: string) => {
  if (status === RatingStatus.ACCEPTED) {
    return RATING_RESULT_TAB.OBJECTION_COMPLETE;
  }

  return undefined;
};

const getActivePage = (ratingData: ReleasedRatings[] | RequestRating) => {
  // Released rating
  if (Array.isArray(ratingData)) {
    // Only direct input case has 2 ratings
    if (ratingData.length > 1 || ratingData[0].directYn === Confirmation.YES) {
      currentActivePageKey.value = RATING_RESULT_TAB.DIRECT_INPUT_COMPLETE;
    } else {
      currentActivePageKey.value =
        getStatus(ratingData[0].status, undefined, ratingData[0].type) ??
        getStatusByType(ratingData[0].type);
    }
  } else if (
    isPreRelease.value &&
    launchInfoFieldChanged.value.length > 0 &&
    ratingData.status === RatingStatus.SAVE &&
    ratingData.verifyInfo?.verifyStatus === VerifyStatus.PASS
  ) {
    currentActivePageKey.value = RATING_RESULT_TAB.PRE_RELEASE;
  } else {
    currentActivePageKey.value =
      getStatus(ratingData.status, ratingData.verifyInfo?.verifyStatus, ratingData.type) ??
      getStatusByType(ratingData.type);
  }

  if (currentActivePageKey.value === RATING_RESULT_TAB.NONE) {
    isGuideShown.value = true;
    return;
  }

  if (currentActivePageKey.value || !rating.value) {
    return;
  }

  currentActivePageKey.value = checkObjectionComplete(rating.value.additionalRating?.status);
};

const getRatingsProducts = async () => {
  try {
    const ratingProduct = await fetchRatingsProductsApi(productNo);
    if (ratingProduct) {
      rating.value = ratingProduct;

      const ratingData = getCorrectRating(
        ratingProduct.requestRating,
        ratingProduct.releasedRatings
      );

      if (ratingData && rating.value) {
        if (rating.value.releasedRatings && rating.value.releasedRatings[0]) {
          // 28-12-20
          const isCanceled =
            rating.value.releasedRatings[0].type === AdditionalRatingType.RATING_CANCELED &&
            rating.value.releasedRatings[0].status === RatingStatus.ADJUSTED;
          // 28-12-22
          const isRejected =
            rating.value.releasedRatings[0].type === AdditionalRatingType.RATING_REJECTED &&
            rating.value.releasedRatings[0].status === RatingStatus.ADJUSTED;

          isGuideShown.value = isCanceled || isRejected;
        } else {
          isGuideShown.value = false;
        }

        rating.value.rating = ratingData;

        const launchInfo = await fetchRatingProductLaunchInfoApi(productNo);
        launchInfoFieldChanged.value = getChangedRatingInformation(launchInfo, product.value).map(
          (fieldName: string) => t(fieldName)
        );

        getActivePage(ratingData);
      }
    }
  } catch (error) {}
};

const getProductReleaseStatusApi = async () => {
  const releasePlanRes = await fetchProductStatusApi(Number(productNo));
  if (releasePlanRes && releasePlanRes.verifyStatus) {
    isProductReleaseVerifying.value =
      releasePlanRes.verifyStatus === VERIFY_STATUS.START ||
      releasePlanRes.verifyStatus === VERIFY_STATUS.REQUEST;
  }
};

const init = async () => {
  getRatingsProducts();
  getProductReleaseStatusApi();
};

init();
</script>
