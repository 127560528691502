<template>
  <st-box class="mt-24">
    <div class="mx-auto flex justify-center flex-col items-center gap-20">
      <img
        src="@/assets/images/build/28-1-2-img.svg"
        alt=""
        class="block h-188 w-272 object-contain"
      />
      <h3 class="break-keep text-center text-3xl font-bold leading-2xl text-on-surface-elevation-1">
        {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.guide1') }}
      </h3>
      <p class="break-keep text-center font-regular text-md leading-lg text-on-surface-elevation-3">
        <safe-html :html="$t('studio.prj_prod_mngmt.rating_build_review.self_review.guide1_1')" />
      </p>
    </div>
    <div class="grid gap-24 grid-cols-3 mt-40">
      <div
        class="min-h-[18rem] rounded-3xl bg-neutral-variant-2-pressed flex flex-col gap-[.6rem] px-24 py-20"
      >
        <p class="text-lg leading-lg font-bold text-on-surface-elevation-1">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.bims_bank_info') }}
        </p>
        <p class="text-sm leading-md font-regular text-on-surface-elevation-2 break-keep">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.bims_bank_info_guide') }}
        </p>
        <span
          class="cursor-pointer mt-auto inline-flex items-center gap-4 text-md leading-sm text-on-surface-elevation-2 font-regular"
          @click="navigateToBusinessBank"
        >
          <span
            class="border-b-1 border-solid border-transparent hover:border-on-surface-elevation-2"
          >{{
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.bims_bank_info_pg_dir_btn')
          }}</span>
          <s-icon icon="ic-v2-control-arrow-right-line" size="xl" class="flex" />
        </span>
      </div>
      <div
        class="min-h-[18rem] rounded-3xl bg-neutral-variant-2-pressed flex flex-col gap-[.6rem] px-24 py-20"
      >
        <p class="text-lg leading-lg font-bold text-on-surface-elevation-1">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.build_upl') }}
        </p>
        <p class="text-sm leading-md font-regular text-on-surface-elevation-2 break-keep">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.build_upl_guide') }}
        </p>
        <span
          class="cursor-pointer mt-auto inline-flex items-center gap-4 text-md leading-sm text-on-surface-elevation-2 font-regular"
          @click="navigateToBuildManagement"
        >
          <span
            class="border-b-1 border-solid border-transparent hover:border-on-surface-elevation-2"
          >{{
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.build_mgmt_pg_dir_btn')
          }}</span>
          <s-icon icon="ic-v2-control-arrow-right-line" size="xl" class="flex" />
        </span>
      </div>
      <div
        class="min-h-[18rem] rounded-3xl bg-neutral-variant-2-pressed flex flex-col gap-[.6rem] px-24 py-20"
      >
        <p class="text-lg leading-lg font-bold text-on-surface-elevation-1">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.game_content') }}
        </p>
        <p class="text-sm leading-md font-regular text-on-surface-elevation-2 break-keep">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.game_content_guide') }}
        </p>
      </div>
    </div>
  </st-box>
</template>
<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import { BUSINESS_BANK_PAGE_URL, PRODUCT_BUILD_PAGE_URL } from '@/constants/url.const';
import { redirectTo } from '@/utils/common.util';

const navigateToBusinessBank = () => {
  redirectTo(BUSINESS_BANK_PAGE_URL);
};

const navigateToBuildManagement = () => {
  redirectTo(PRODUCT_BUILD_PAGE_URL);
};
</script>
